import React, { Component } from "react";

// Lib
import Helmet from "react-helmet";
import i18n from "../../../i18n";

// Components
import ProfileContainer from "../container/ProfileContainer";

class ProfilePage extends Component {
  render() {
    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;

    return (
      <div className="profile">
        <Helmet title={i18n.t("profile__profile_title")} />
        <ProfileContainer 
          lookup_code={this.props.location.state && this.props.location.state.lookup_code} 
          dataSource={this.props.location.state && this.props.location.state.dataSource} 
          isLoginFailedError={this.props.location.state && this.props.location.state.error} 
          isInternetDisconnected={isInternetDisconnected}   
          handleToggleNetworkModal={handleToggleNetworkModal}
        />
      </div>
    );
  }
}

export default ProfilePage;