import React, { Component } from "react";

// Library
import i18n from "../../../i18n";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AnalyticsService from "analytics-web";

// Components
import LogoutModal from "../../LogoutModal";
import DeleteAccountModal from "../presentation/DeleteAccountModal";
import IconSuccessModal from "../../IconSuccessModal";
import ErrorModal from "../../ErrorModal";

// Service
import ProfileService from "../../Services/ProfileService";
import { AuthService } from "../../Services/AuthService";
import { BrowserStorage } from "../../Services/BrowserStorage";

const default_settings_config = [{
    "header": "settings__more_title",
    "items": [{
      "type": "logout",
      "image_url": "",
      "title": "logout__logout"
    }]
}];

class SettingsContainer extends Component {
  state = {
    setting_configs: window.config.setting_configs && window.config.setting_configs.length ? window.config.setting_configs : default_settings_config,
    toggleLogoutModal: false,
    profile: {
      email: "",
      name: "",
      idp_name: "",
      logo_url: "",
      sub: "",
      verified_sources: []
    },
    isLoadingProfile: true,
    profile_configs: window.config.profile_configs,
    deleteFormInputs: {
      reason: {
        label: i18n.t("settings__delete_account_modal_select_default_option"),
        value: ""
      },
      deleteField: "",
      isInvalidInputs: true
    },
    toggleDeleteModal: false,
    deleteReasons: [],
    toggleSuccessModal: false,
    isSendingReq: false,
    hasError: false,
    errorMessage: "",
    errorBody: "",
    handleErrorAction: null,
  };

  componentDidMount = async () => {
    const { deleteFormInputs, toggleDeleteModal, deleteReasons, setting_configs } = this.state;
    // Analytics
    this.handleAnalyticsTrack("onLoad_event");

    let tempDeleteFormInputs = deleteFormInputs;
    let tempToggleDeleteModal = toggleDeleteModal;
    let tempDeleteReasons = deleteReasons;
    const localDeleteReason = BrowserStorage.get("delete_reason");
    
    // 1. Check for previous delete account IF there was 403 error.....
    // Opens the Delete Modal with previous state
    if (localDeleteReason) {
      tempDeleteFormInputs = {
        reason: {
          label: i18n.t(localDeleteReason),
          value: localDeleteReason
        },
        deleteField: "DELETE",
        isInvalidInputs: false
      }
      tempToggleDeleteModal = true;

      // 2. Get the Settings reasons
      setting_configs.length && setting_configs.map(config => {
        config.items.map(item => {
          if (item.type === "delete_account") {
            tempDeleteReasons = item.reasons;
          }

          return item;
        })

        return config;
      })
      
      // 3. Remove the history
      BrowserStorage.remove("delete_reason");
    }

    // Call Profile Endpoint
    const profile = await ProfileService.getMyProfile();

    this.setState({
      profile,
      isLoadingProfile: false,
      deleteFormInputs: tempDeleteFormInputs,
      toggleDeleteModal: tempToggleDeleteModal,
      deleteReasons: tempDeleteReasons
    })
  }

  handleCategoryList = (settings_config) => {
    const { 
      profile_configs
    } = this.state;
    
    return (
      <li 
        key={settings_config.type }     
      >
        <button className={this.handleClassNameForTitleBtton(settings_config)} onClick={this.handleListItemOnClick.bind(this, settings_config)}>
          <div className="left-side">
              {/* Icon */}

              { this.handleIconImage(settings_config) }
          </div>
          <div className="middle">
            <div className="heading-section">
              
              {/* Title */}
              {
                settings_config.type === "current_account" 
                ?
                <>
                  {
                    (profile_configs && profile_configs.name)
                    &&
                    <div className="title profile-user-name textPrimaryColor">
                      {
                        this.handleProfileNameRender()
                      }
                    </div>
                  }
                </>
                :
                <>
                  {
                    settings_config.title
                    &&
                    <div className="title textPrimaryColor">{i18n.t(settings_config.title)}</div>
                  }
                </>
              }
            </div>          

            {/* Subtitle */}

            {
              settings_config.subtitle
              &&
              <div className="sub-heading">{i18n.t(settings_config.subtitle)}</div>
            }
          </div>
          
          {/* Arrow */}

          {
            settings_config.has_arrow
            &&
            <div className="right-side">
              <ArrowRightIcon className="caretStyle textPrimaryColor" />
            </div>
          }
        </button>
      </li>
    );
  }

  handleClassNameForTitleBtton = (settings_config) => {
    let tempClasses = "actionbtn";

    if (settings_config.type === "current_account") {
      if (!settings_config.has_arrow) {
        tempClasses = "actionbtn non-clickable";
      }
    }

    return tempClasses;
  }

  handleProfileNameRender = () => {
    const { profile, isLoadingProfile } = this.state;

    let tempName;

    if (!profile.name && !isLoadingProfile) {
      tempName = <span>-</span>;
    } else {
      tempName = <span>{profile.name}</span>;
    }
      
    return tempName;
  }

  handleListItemOnClick = (settings_config) => {
    const {
      handleToggleNetworkModal,
      isInternetDisconnected,
    } = this.props;

    const { profile } = this.state;

    let tempButtonAction;
    let tempAnalyticsActions = i18n.t(settings_config.title);

    if (!isInternetDisconnected) {
      if (settings_config.link && settings_config.link.url) {
        tempButtonAction = this.handleRedirectUrl(settings_config.link.url, settings_config.link.is_external);
      } else {
        if (settings_config.type === "current_account" && settings_config.has_arrow && profile.manage_account_url) {
          tempButtonAction = this.handleRedirectManageUrl();
          tempAnalyticsActions = "Current Account";
        } else if (settings_config.type === "managed_accounts") {
          tempButtonAction = this.handleManageAccounts();
        }else if (settings_config.type === "logout") {
          tempButtonAction = this.handleToggleLogoutModal();
        } else if (settings_config.type === "delete_account") {
          tempButtonAction = this.handleToggleDeleteModal(settings_config.reasons);
        } else {
          tempButtonAction = () => { 
            //Return Empty so button won't throw error
          };
        }
      }
    } else {
      tempButtonAction = handleToggleNetworkModal();
    }

    // Analytics
    this.handleAnalyticsTrack("onClick_event", `Clicked - ${tempAnalyticsActions}`);

    return tempButtonAction;
  }


  handleIconImage = (settings_config) => {
    let tempImage;

    if (!settings_config.image_url) {
      if (settings_config.type === "current_account") {
        tempImage = <span className="icon-icon-user-account"></span>;
      } else if (settings_config.type === "managed_accounts") {
        tempImage = <span className="icon-icon-managed-accounts"></span>;
      }else if (settings_config.type === "logout") {
        tempImage = <span className="icon-icon-logout"></span>;
      } else if (settings_config.type === "delete_account") {
        tempImage = <span className="icon-icon-delete"></span>;
      } 
    } else {
      tempImage = <img src={settings_config.image_url}  alt="" role="presentation" />;
    }

    return tempImage;
  }

  // Logout

  handleToggleLogoutModal = () => {
    const { toggleLogoutModal } = this.state;

    if (toggleLogoutModal) {

      // Analytics
      this.handleAnalyticsTrack("onClick_event", `Logout Popup - Cancel`);
    }

    this.setState({  toggleLogoutModal: !toggleLogoutModal })
  }

  handlelogoutAction = async (is403Error) => {
    const { deleteFormInputs } = this.state;

    await AuthService.logout();
  
    // For 403 case save the history for delete reason
    if (is403Error === true) {
      BrowserStorage.set("delete_reason", deleteFormInputs.reason.value);
    }
 
    // Analytics
    this.handleAnalyticsTrack("onClick_event", `Logout Popup - Logout`);
  }

  // Manage Url
  handleRedirectManageUrl = () => {
    const { profile } = this.state;

    const tempManageUrl = `${profile.manage_account_url}?return_url=${window.location.href}&user_id=${profile.idp_sub}`;
   
    window.location.assign(tempManageUrl);
  }

  // Manage Accounts

  handleManageAccounts = () => {
    console.log("handleManageAccounts")
  }

  // Delete Account

  handleToggleDeleteModal = (deleteReasons) => {
    const { toggleDeleteModal } = this.state;
    
    if (toggleDeleteModal) {
      //Analytics
      this.handleAnalyticsTrack("onClick_event", `Delete Account Popup - Cancel`);
    }

    this.setState({ 
      toggleDeleteModal: !toggleDeleteModal, 
      deleteReasons: deleteReasons || ["settings__delete_account_modal_select_option_none"], deleteFormInputs: {
        reason: {
          label: i18n.t("settings__delete_account_modal_select_default_option"),
          value: ""
        },
        deleteField: "",
        isInvalidInputs: true
      }
    });
  }

  // On Change method for the inputs

  handleDeleteFormOnChange = (e) => {
    const { deleteFormInputs } = this.state;
    const { name, value } = e.target;
    
    let isInvalidInputs = deleteFormInputs.isInvalidInputs;

     // Correct Fields check
    if (deleteFormInputs.reason && deleteFormInputs.reason.value && name === "deleteField" && value === "DELETE") {
      isInvalidInputs = false;
    }

    this.setState({ deleteFormInputs: {...deleteFormInputs, [name]: value, isInvalidInputs } });
  }

  // On Change method for the Select
  
  handleDeleteFormSelectOnChange = (selectedOption, action) => {
    const { deleteFormInputs } = this.state;
    
    let isInvalidInputs = deleteFormInputs.isInvalidInputs;
   
     // Correct Fields
    if (selectedOption.value && deleteFormInputs.deleteField === "DELETE") {
      isInvalidInputs = false;
    }

    // Anaytics for the Select
    this.handleAnalyticsTrack("onSelect_event", `Reason - ${i18n.t(selectedOption.value)}`);

    this.setState({ deleteFormInputs: {...deleteFormInputs, [action.name]: { label: i18n.t(selectedOption.value), value: selectedOption.value }, isInvalidInputs } });
  }

  // On Focus analytics for the DELETE Input

  handleInputOnBlur = (e) => {
    this.handleAnalyticsTrack("onFocus_event", `${e.target.name} - ${e.target.value}`);
  }

  // Validation for inputs

  componentDidUpdate = (prevProps, prevState) => {
    const { deleteFormInputs } = this.state;

    let tempisInvalidInputs = deleteFormInputs.isInvalidInputs;
  
    // When Either field is empty
    if ((prevState.deleteFormInputs.reason.value && !deleteFormInputs.reason.value) || (prevState.deleteFormInputs.deleteField !== deleteFormInputs.deleteField && deleteFormInputs.deleteField !== "DELETE" )) {
      tempisInvalidInputs = true;

      this.setState({ deleteFormInputs: {...deleteFormInputs, isInvalidInputs: tempisInvalidInputs } })
    }
  }

  // Redirect Url

  handleRedirectUrl = (link, isExternal) => {
    if (isExternal) {
      window.open(link, '_blank');
    } else {
      window.location.assign(link);
    }
  }

  // Tracking

  handleAnalyticsTrack = (type, action) => {
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: "Settings Page",
      actions: action,
      url: window.location.hostname + window.location.pathname
    })
  }
  
  // Handle Delete Action

  handleDeleteAction = async () => {
    const { deleteFormInputs } = this.state;

    // Button Click to delete account
    //Analytics
    this.handleAnalyticsTrack("onClick_event", `Delete My Account`);
    
    try {
      this.setState({ isSendingReq: true });

      const result = await ProfileService.selfDelete(deleteFormInputs.reason.label);
          
      if (result && result.account_id) {
        this.setState({
          toggleSuccessModal: true,
          isSendingReq: false,
          toggleDeleteModal: false
        })

        // On Delete Success
        //Analytics
        this.handleAnalyticsTrack("onDelete_event", `Wallet Account`);
      }
    } catch (e) {
      let errorTitle = i18n.t("settings__delete_account_error_modal_title_text");
      let errorMessage = i18n.t("settings__delete_account_error_modal_body_text");
      let hasError = false;
      let isSendingReq = false;
      let toggleDeleteModal = false;
      let handleErrorAction;

      if (e && e.status) {
        if (e.status === 403) {
          errorTitle = i18n.t("session_modal__title--expiry");
          errorMessage = i18n.t("session_modal__message--expiry");
          hasError = true;
          handleErrorAction = () => this.handlelogoutAction(true);
        }
  
        if (e.status !== 401 && e.status !== 403) {
          hasError = true;
          handleErrorAction = null;
        }
      } 

      this.setState({
        hasError,
        isSendingReq,
        toggleDeleteModal,
        errorTitle,
        errorMessage,
        handleErrorAction,
      });
      
      //Analytics
      this.handleAnalyticsTrack("error", `Delete My Account - ${e.status}`);
    }
  }

  render() {
    const { setting_configs, toggleLogoutModal, toggleDeleteModal, deleteFormInputs, deleteReasons, toggleSuccessModal, isSendingReq, hasError,  errorTitle, errorMessage, handleErrorAction } = this.state;

    const { isInternetDisconnected, handleToggleNetworkModal } = this.props;
  
    return (
      <div className="settings-container">
        <h1>{i18n.t("settings__title")}</h1>

        {/* Activity */}

        {
          setting_configs.map(config => (
            <section className="box-wrapper" key={config.header}>
              <h2>{i18n.t(config.header)}</h2>

              {
                config.items && config.items.length 
                ?
                <ul className="list-box">
                  {
                    config.items.map(item => (
                      this.handleCategoryList(item)
                    ))
                  }
                </ul>
                :
                null  
              }
            </section>
          ))
        }

        {
          toggleLogoutModal 
          && 
          <LogoutModal 
            isOpen={toggleLogoutModal}
            handleToggleLogoutModal={this.handleToggleLogoutModal}
            handlelogoutAction={this.handlelogoutAction}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
          />
        }

        {
          toggleDeleteModal
          &&
          <DeleteAccountModal
            deleteReasons={deleteReasons}
            isOpen={toggleDeleteModal}
            handleToggleDeleteModal={this.handleToggleDeleteModal}
            handleDeleteFormOnChange={this.handleDeleteFormOnChange}
            handleDeleteFormSelectOnChange={this.handleDeleteFormSelectOnChange}
            deleteFormInputs={deleteFormInputs}
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
            handleDeleteAction={this.handleDeleteAction}
            isSendingReq={isSendingReq}
            handleInputOnBlur={this.handleInputOnBlur}
          />
        }

        {
          toggleSuccessModal
          &&
          <IconSuccessModal
            isOpen={toggleSuccessModal} 
            titleText={i18n.t("settings__delete_account_success_modal_title_text")}
            bodyText={i18n.t("settings__delete_account_success_modal_body_text")}
            buttonText={i18n.t("settings__delete_account_success_modal_button_text")} 
            handleButtonAction={this.handlelogoutAction} 
            isInternetDisconnected={isInternetDisconnected}
            handleToggleNetworkModal={handleToggleNetworkModal}
          />
        }

        {
          hasError
          &&
          <ErrorModal 
            errorTitle={errorTitle}
            errorMessage={errorMessage}
            isOpen={hasError}
            handleToggleModal={handleErrorAction}
          />
        }
      </div>
    );
  }
}

export default SettingsContainer;