import React, { Component } from "react";

// Lib
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';
import Modal from "react-modal";
import AnalyticsService from "analytics-web";

// Routes
import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import { DefaultRoute } from "./DefaultRoute";

// Components
import LoginPage from "./components/LoginPage/layout/LoginPage";
import RequestErrorPage from "./components/RequestErrorPage/layout/RequestErrorPage";
import ProfilePage from "./components/ProfilePage/layout/ProfilePage";
import ConnectionsPage from "./components/ConnectionsPage/layout/ConnectionsPage";
import ConnectionDetailPage from "./components/ConnectionDetailPage/layout/ConnectionDetailPage";
import ConsentPage from "./components/ConsentPage/layout/ConsentPage";
import ConsentQRPage from "./components/ConsentQRPage/layout/ConsentQRPage";
import FindConnectionPage from "./components/FindConnectionPage/layout/FindConnectionPage";
import SettingsPage from "./components/SettingsPage/layout/SettingsPage";
// import ActivityRequestPage from "./components/ActivityRequestPage/layout/ActivityRequestPage";
import ConnectionResourceDetailPage from "./components/ConnectionResourceDetailPage/layout/ConnectionResourceDetailPage";
import ConnectionSPDetailPage from "./components/ConnectionSPDetailPage/layout/ConnectionSPDetailPage";
import ConfirmAuthorizationPage from "./components/ConfirmAuthorization/layout/ConfirmAuthorizationPage";
import StandardLayout from "./components/Layout/StandardLayout";
import WithoutSidebarLayout from "./components/Layout/WithoutSidebarLayout";
import SessionModals from "./components/SessionModals";
import IdpRedirectRouter from "./components/IdpRedirectRouter";
import RsRedirectRouter from "./components/RsRedirectRouter";
import AsRequestRouter from "./components/AsRequestRouter";

// Utils
import i18n from './i18n';

// Css
import "./styles/App.css";

export default class App extends Component {
  state = {
    ready: false,
    showSessionModal: false,
    isErrorFlag: false,
  }

  componentDidMount() {
    let that = this;
    let startup = [];

    // Modal Accessibility Warning Fix
    Modal.setAppElement('#root');

    startup.push(new Promise((resolve, reject) => {
      axios.get("./config/config.json").then(function(res) {
        window.config = res.data;

        if (window.config.whitelabel) {

          var headID = document.getElementsByTagName('head')[0];
          var link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';

          headID.appendChild(link);

          link.href = window.config.whitelabel;
          
        }


        resolve();
      });
    }));
   
    startup.push(new Promise((resolve, reject) => {
      i18n.on('initialized', function() { 
        resolve();
      });
    }));
   
    return Promise.all(startup).then(function() {

      // Analytics
      AnalyticsService.initialize(window.config.analytics_config.alias, {
        instrumentationKey: window.config.analytics_config.instrumentationKey,
        disableAjaxTracking: true,
      });

      that.handleAxiosInterceptor();
      that.setState({ 
        ready: true,
      });
    })
  }
  
  handleAxiosInterceptor = () => {
    // Add a response interceptor
    axios.interceptors.response.use(
      response => response,
      error => this.errorHandler(error)
    )
  }

  isInterceptorEnabled = (config) => {
    return ((config.baseURL === window.config.url && !config.headers.excludeInterceptor) ? true : false)
  }
  
  // Error Handler
  errorHandler = (error) => { 
    const { isErrorFlag } = this.state;
    if (!isErrorFlag && this.isInterceptorEnabled(error.config) && error.response.status === 401) {

      // Handle errors
      this.setState({ 
        showSessionModal: true, 
        isErrorFlag: true 
      });
    }
    return Promise.reject({...error})
  }

  render() {
    const { showSessionModal, ready } = this.state;

    if (!ready) {
      return <div />;
    }
    
    const titleText = i18n.t("title");
    const noscriptText = i18n.t("noscript");

    return (
      <div className="App">
        <Helmet>
          <meta charset={i18n.t("charset")}/>
          <link href={i18n.t('favicon')} />
          <title>{titleText.toString()}</title>
          <link rel="shortcut icon" href={window.config.favicon} />
          <noscript>{noscriptText.toString()}</noscript>
        </Helmet>

        <div className="app-wrapper">
          <Router>
            <main>
              <Switch>
                <PublicRoute exact path={window.GLOBAL_PATH} component={LoginPage} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"welcome"} component={LoginPage} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"idp-callback"} component={IdpRedirectRouter} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"rs-callback"} component={RsRedirectRouter} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"as-authorize"} component={AsRequestRouter} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"consent-request"} component={ConsentQRPage} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"confirm-authorization"} component={ConfirmAuthorizationPage} layout={WithoutSidebarLayout} />
                <PublicRoute exact path={window.GLOBAL_PATH+"request-error"} component={RequestErrorPage} layout={WithoutSidebarLayout} />

                <ProtectedRoute exact path={window.GLOBAL_PATH+"consent/:transactionID"} component={ConsentPage} layout={WithoutSidebarLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"profile"} component={ProfilePage} layout={StandardLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"my-account/find-connections"} component={FindConnectionPage} layout={WithoutSidebarLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"my-activity"} component={ConnectionsPage} layout={StandardLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"my-activity/connections/find-connection"} component={FindConnectionPage} layout={WithoutSidebarLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"my-activity/connections/:organizationName/:organizationID"} component={ConnectionDetailPage} layout={StandardLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"my-activity/connections/:organizationName/:organizationID/:resourceID"} component={ConnectionResourceDetailPage} layout={StandardLayout} />
                <ProtectedRoute exact path={window.GLOBAL_PATH+"my-activity/connections/:organizationName/:organizationID/:clientIdentifier/:resourceID"} component={ConnectionSPDetailPage} layout={StandardLayout} />
                <ProtectedRoute path={window.GLOBAL_PATH+"settings"} component={SettingsPage} layout={StandardLayout} />
                {/* <ProtectedRoute path="/settings/:pathName" component={ActivityRequestPage} layout={StandardLayout} />  */}
                
                {/* For the invalid urls redirect to loginpage */}
                <DefaultRoute />
              </Switch>
            </main>
          </Router>
        </div>
        
        {
          showSessionModal
          &&
          <SessionModals
            isOpen={showSessionModal}
          />
        }
      </div>
    );
  }
}
