import React, { Component } from "react";

// Lib
import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

// Services
import i18n from "../i18n";

class Header extends Component {
  render() {
    const { handleDrawerToggle, hasMenu, isSidebar } = this.props;

    return (
      <div className="container">
        <Link to={window.GLOBAL_PATH} className="logo-link" dangerouslySetInnerHTML={{ __html: i18n.t("logo_image") }} />

        {
          (handleDrawerToggle && isSidebar)
          &&
          <button
              aria-label="close sidebar" 
              onClick={handleDrawerToggle}
              className="menu-button"
          >
              <CloseIcon className = "arrow-icon" />
          </button>
        }

        {
          hasMenu
          &&
          <button
            aria-label="menubar" 
            onClick={handleDrawerToggle}
            className="menu-btn menu-button--mobile textPrimaryColor"
          >
            <MenuIcon className="menu-icon" />
          </button>
        }
      </div>
    );
  }
}

export default Header;
