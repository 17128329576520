import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

// Services
import DatasourceService from "../../Services/DatasourceService";
import ProfileService from "../../Services/ProfileService";

// Lib
import i18n from "../../../i18n";
import { orderBy, debounce } from 'lodash';
import Moment from 'react-moment';
import AnalyticsService from "analytics-web";

// Components
import NewConnectionModal from "../presentation/NewConnectionModal";
import ErrorModal from "../../ErrorModal";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MobileVersion from "../presentation/MobileVersion";
import DesktopVersion from "../presentation/DesktopVersion";
import HeaderSection from "../presentation/HeaderSection";
import EmptyScreenMessage from "../presentation/EmptyScreenMessage";

class ConnectionsContainer extends Component {

  state = {
    isLoading: true,
    newConnectionToggle: false,

    connectionFields: [],
    organizationsList: [],
    paginationOrganizationsList: [],

    searchResults: [],
    searchPaginationResults: [],
    onSortClick: false,
    queryString: "",

    hasError: false,
    errorTitle: "",
    errorMessage: "",
    errorButtonText: i18n.t("ok"),

    isPaginationLoader: false,
    limit: window.config.connectionsPagination,
    pageNumber: 1,
    searchPageNumber: 1,
    my_activity_configs: window.config.my_activity_configs
  }

  componentDidMount = () => {
    let isSmallScreen = false;
    
    if (window.innerWidth <= 750) {
      isSmallScreen = true;
    }
    
    // Table Fields
    // 1. These are required for lib table
    this.handleMobileDevice(isSmallScreen);

    // 2. Get all the Data Sources
    this.handleSources();
  }

  handleSources = () => { 
    const { limit } = this.state;
    const promises = [];

    //Analytics
    this.handleAnalyticsTrack("onLoad_event");

    // 1. Api call to get all the accounts
    promises.push(DatasourceService.getOrganizationAccounts().then((orgs) => {

      // Alphabetical Sort
      orgs.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      return orgs;
    }));
    
    return Promise.all(promises).then(async (result) => {
      
      let orgs = result[0];
      
        orgs.map(org => {        
          // 2.1 Information Source
          // If rot is there
          
          if (org.accounts && org.accounts.length) {
          org.accounts.map(account => {

            // Add Expiry to the account
            DatasourceService.isAccountExpired(account)

            // Add the tag 
            if (account.rot) {
              account.orgType = i18n.t("connectionDetail__informationSource");
            }
    
            return account;
          });
        }
        
        // 2.2 Service provider
        // If clients array have length
  
        return org;
      });
      
      // Accounts and clients grouping

      orgs = await Promise.resolve(this.handleDataGrouping(orgs));
    
      this.setState({
        organizationsList: orgs,
        paginationOrganizationsList: orgs.slice(0, limit),
        pageNumber: 2,
        isLoading: false,
      });
    }).catch(e => {

      if (e.status && e.status !== 401) {
        this.setState({
          isLoading: false,
          hasError: true,
          errorTitle: i18n.t("connections_failure_load_title"),
          errorMessage: i18n.t("connections_failure_load_desc")
        });
      }
    })
  }

  handleDataGrouping = (orgList) => {
    
    return new Promise((resolve) => { 
      let result = [];

      orgList.forEach((org) => {
        // Each Account
  
        org.accounts && org.accounts.length && org.accounts.forEach((account) => {
          account.orgInfo = { name: org.name , id: org.id };
          result = [...result, account];
        })
  
        // Clients
  
        if (org.clients && org.clients.length) {
  
          const last_active = this.handleLastActive(org.clients);
  
          const formattedObj = {
            clients: org.clients,
            orgInfo: {
              id: org.id,
              name: org.name,
            },
            orgType: i18n.t("connectionDetail__serviceProvider"),
            last_active
          }
         
          result = [...result, formattedObj];
        }
      });

      resolve(result);
    })
  } 

  handleLastActive = (clients) => {
    let lastActiveTimeArray = [];

    clients.map(client => {
      lastActiveTimeArray.push(client.last_active);

      return client;
    })
 
    lastActiveTimeArray.sort((a, b) => new Date(b) - new Date(a));

    return lastActiveTimeArray[0];
  }


  handleToggleConnection = () => {
    const { newConnectionToggle } = this.state;

    this.setState({ newConnectionToggle: !newConnectionToggle })
  }
  
  // Redirect when clicked on a rows

  handleOnClickName = (data) => {

    let pathname;
    let analyticsDesc;
    
    if (data.orgType === i18n.t("connectionDetail__serviceProvider")) {
      pathname = window.GLOBAL_PATH+`my-activity/connections/${data.orgInfo.name}/${data.orgInfo.id}`;
      analyticsDesc = `${data.orgInfo.name} - ${data.orgType}`;
    } else {
      pathname = window.GLOBAL_PATH+`my-activity/connections/${data.orgInfo.name}/${data.ds_account_id}`;
      analyticsDesc = `${data.orgInfo.name} - ${data.nickname} ${data.orgType}`;
    }
    //Analytics
    this.handleAnalyticsTrack("onClick_event", analyticsDesc);

    this.props.history.push({
      pathname,
      state: {
        data
      }
    });
  }

  // Sorting Functions

  handleOnSortClick = () => {
    const { onSortClick } = this.state;

    this.setState({ onSortClick: !onSortClick })
  }

  customSort = (rows, field, direction) => {
    const handleField = row => {
      if (row[field]) {
        return row[field].toLowerCase();
      }

      if (field === "name"){
        return `${row.orgInfo.name} ${row.nickname}`;
      }

      if (field === "type"){
        return row.orgType;
      }

      if (field === "lastActive") {
        return row.last_active;
      } 

      return row[field];
    };

    return orderBy(rows, handleField, direction);
  }

  // Search input 

  handleOnChange = (e) => {
    this.setState({
      queryString: e.target.value ? e.target.value : "",
      isLoading: true
    })
  }

  // Search Validations

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.queryString !== this.state.queryString && this.state.queryString) {
      this.handleSearch(this.state.queryString);
    }

    if (prevState.queryString && !this.state.queryString) {
      this.setState({ searchResults: [], isLoading: false })
    }
  }

  handleSearch = debounce((query) => {
    const { limit, organizationsList } = this.state;
    
    //Analytics
    this.handleAnalyticsTrack("onSubmit_event", `Search connection - ${query}`)

    let tmpResults = [];
    
    for (let orgItem of organizationsList) {
      for(let key in orgItem) {

        // Org Name search
        if (key === 'orgInfo' && orgItem[key].name.toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) {
          tmpResults.push(orgItem);
          break;
        }

        // Nick name search
        if (key === 'nickname' && orgItem[key].toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) {
          tmpResults.push(orgItem);
          break;
        }

        // Org Type search
        if (key === 'orgType' && orgItem[key].toLowerCase().indexOf(query.trim().toLowerCase()) !== -1) {
          tmpResults.push(orgItem);
          break;
        }
      }
    }

    return this.setState({
      searchResults: tmpResults,
      searchPaginationResults: tmpResults.slice(0, limit),
      searchPageNumber: 2,
      isLoading: false
    })
  }, 500);

  handleFindConnections = () => {
    this.props.history.push({ 
      pathname: window.GLOBAL_PATH + "my-account/find-connections",
      state: {
        previousPage: "CONNECTIONS_PAGE"
      }
    })
  }

  handleToggleErrorModal = () => {
    const { hasError } = this.state;

    this.setState({ hasError: !hasError })
  }

  // Table Columns based on screen width

  handleMobileDevice = (matches) => {
    let fields = [];
    
    if (matches) {
      fields = [{
        selector: "mobile",
        minWidth: 'unset',
      }];
    } else {
      fields = [{
        name: i18n.t("connections__information"),
        selector: "name",
        minWidth: 'unset',
      }, {
        name: i18n.t("connections__type"),
        selector: "type",
        minWidth: 'unset',
      }, {
        name: i18n.t("connections__lastActive"),
        selector: "lastActive",
        minWidth: 'unset',
      }];
    }

    this.setState({ connectionFields: fields })
  }

  // Mobile Pagination Functions

  fetchMoreItems = () => {
    const {
      pageNumber,
      searchPageNumber,
      limit,
      organizationsList,
      paginationOrganizationsList,
      searchResults,
      searchPaginationResults,
      queryString
    } = this.state;
  
    this.setState({ isPaginationLoader: true  });

    let offsetCalc;
    let nextPaginationList = [];
    
    if (searchPaginationResults.length && queryString) {
      offsetCalc = (limit * (searchPageNumber - 1));
      nextPaginationList = searchResults.slice(offsetCalc).slice(0, limit);

      this.setState({
        isPaginationLoader: false,
        searchPageNumber: searchPageNumber + 1,
        searchPaginationResults: [...searchPaginationResults, ...nextPaginationList]
      });
    } else {

      offsetCalc = (limit * (pageNumber - 1));
      
      nextPaginationList = organizationsList.slice(offsetCalc).slice(0, limit);

      this.setState({
        isPaginationLoader: false,
        pageNumber: pageNumber + 1,
        paginationOrganizationsList: [...paginationOrganizationsList, ...nextPaginationList]
      });
    }
  }

  handleHasMore = () => {
    const {
      organizationsList,
      searchResults,
      searchPaginationResults,
      paginationOrganizationsList,
    } = this.state;

    let hasMore = false;

    if ((searchResults.length >= searchPaginationResults.length) || (organizationsList.length >= paginationOrganizationsList.length)) {
      hasMore = true;
    }

    return hasMore;
  }

  // Tracking

  handleAnalyticsTrack = (type, action) => {
    AnalyticsService.track(type, {
      context: 'Wallet',
      page: "My Information Page",
      actions: action,
      url: window.location.hostname + window.location.pathname
    })
  }

  render() {
    const {
      isLoading,
      newConnectionToggle,
      connectionFields,
      organizationsList,
      onSortClick,
      queryString,
      searchResults,
      searchPaginationResults,
      hasError,
      errorTitle,
      errorMessage,
      errorButtonText,

      paginationOrganizationsList,
      isPaginationLoader,
      limit,
      my_activity_configs
    } = this.state;
  
    const { handleReload, isInternetDisconnected } = this.props;
    
    //=================================================
    // Formatting fields data with table settings start
    //=================================================

    const connectionHeaderFields = connectionFields && connectionFields.map(item => {
      
      // Make all three rows sortable
      item.sortable = true;
      
      // Add custom settings for each column
      if(item.selector === "type") {
        item.cell = row => (
          row.orgType
          ?
          <div className="type-column"><div>{row.orgType}</div></div>
          :
          <div className="empty">&#8213;</div>
        )
      } 
      
      if (item.selector === "name") {
        item.cell = row => (
          <React.Fragment>
            <button onClick={this.handleOnClickName.bind(this, row)} className="name-button textPrimaryColor">

              <p>
                {
                  row.orgType === i18n.t("connectionDetail__serviceProvider")
                  ?
                  <span>{row.orgInfo.name}</span>
                  :
                  <span>{row.orgInfo.name} - <strong>{row.nickname}</strong></span>
                }
                
                <ArrowRightIcon className="caretStyle textPrimaryColor" />
              </p> 
            </button>

            {
              (row.expired && !row.disabled)
              &&
              <span className="error">
                {i18n.t("connections__expired_connection_error")}
              </span>
            }
          </React.Fragment>
        )
      }
      
      if (item.selector === "lastActive") {
        item.cell = row => (
          row.last_active
          ?
          <Moment fromNow className="last-active" locale={ProfileService.getBrowserLanguage()}>{row.last_active}</Moment>
          :
          <div className="empty">&#8213;</div>
        ) 
      }


      // Mobile version table
    
      if (item.selector === "mobile") {
        item.cell = row => (
          <button 
            className="row--mobile"
            onClick={this.handleOnClickName.bind(this, row)}
          >

            <div className="name textPrimaryColor">
              <p>
                {
                  row.orgType === i18n.t("connectionDetail__serviceProvider")
                  ?
                  <span>{row.orgInfo.name}</span>
                  :
                  <span>{row.orgInfo.name} - <strong>{row.nickname}</strong></span>
                }
                
                <ArrowRightIcon className="caretStyle textPrimaryColor" />
              </p> 
            </div>
            
            {
              row.orgType
              &&
              <div className="org-type">
                <span>{row.orgType}</span>
              </div>
            }

            {
              (row.expired && !row.disabled)
              &&
              <div className="error">
                {i18n.t("connections__expired_connection_error")}
              </div>
            }
          </button>
        )
      }
      
      return item;
    })

    //=================================================
    // Formatting fields data with table settings end
    //=================================================

    // Differentiation between search results and connnection list

    let tableData = [];

    if (searchResults.length && queryString) {
      tableData = searchResults;
    } else if (organizationsList.length) {    
      tableData = organizationsList;
    }

    // NO DATA MESSAGES

    let noDataErrorMessage = "";

    if (isInternetDisconnected && !organizationsList.length) {
      noDataErrorMessage = (
        <EmptyScreenMessage 
          title={i18n.t("connections__internet_failure_load_title")} 
          body={i18n.t("connections__internet_failure_load_desc")} 
          actionBtn={<button className="btn backgroundPrimaryColor" onClick={handleReload}>{i18n.t("retry")}</button>} 
        />
      )
    } 
    
    if (!isLoading && !organizationsList.length) {
      noDataErrorMessage = (
        <EmptyScreenMessage 
          title={i18n.t("connections__noDataTitle")} 
          body={i18n.t("connections__noDataDesc")} 
          actionBtn={
            my_activity_configs.multiple_accounts_enable
            &&
            <button className="btn backgroundPrimaryColor" onClick={this.handleFindConnections}>{i18n.t("connections__find_connections_btn")}</button>
          } 
        />
      )
    }

    return (
      <div className="connections-container">
        <HeaderSection
          handleOnChange={this.handleOnChange} 
          queryString={queryString}
        />
        
        {
          noDataErrorMessage
          ?
          noDataErrorMessage
          :
          <React.Fragment>

            {/* Desktop Version */}
            
            <DesktopVersion
              searchResults={searchResults}
              queryString={queryString}
              isLoading={isLoading}
              isInternetDisconnected={isInternetDisconnected}
              tableData={tableData}
              connectionHeaderFields={connectionHeaderFields}
              onSortClick={onSortClick}
              customSort={this.customSort}
              handleOnSortClick={this.handleOnSortClick}
              limit={limit}
            />

            {/* </Mobile Version> */}

            <MobileVersion
              handleMobileDevice={this.handleMobileDevice}
              searchPaginationResults={searchPaginationResults}
              paginationOrganizationsList={paginationOrganizationsList}
              fetchMoreItems={this.fetchMoreItems}
              handleHasMore={this.handleHasMore}
              isPaginationLoader={isPaginationLoader}
              queryString={queryString}
              isLoading={isLoading}
              isInternetDisconnected={isInternetDisconnected}
              customSort={this.customSort}
              connectionHeaderFields={connectionHeaderFields}
              handleOnSortClick={this.handleOnSortClick}
              onSortClick={onSortClick}
            />
          </React.Fragment>
        }

        {
          newConnectionToggle 
          && 
          <NewConnectionModal 
            newConnectionToggle={newConnectionToggle} 
            handleToggleConnection={this.handleToggleConnection} 
          />
        }

        {
          hasError
          &&
          <ErrorModal 
            isOpen={hasError}
            errorTitle={errorTitle}
            errorMessage={errorMessage} 
            errorButtonText={errorButtonText}
            handleToggleModal={this.handleToggleErrorModal}
          />
        }
      </div>
    );
  }
}

export default withRouter(ConnectionsContainer);
