import IdpApi from "./IdpAPI";
import { BrowserStorage, SessionStorage } from "./BrowserStorage";

// Servicess
import ConsentRequestService from "./ConsentRequestService";

// Lib
import  { detectDevice } from "../../utils/CommonFunctions.js";

let IDPConnectService = {};

var IDPAfter = Object.freeze({
    auth: "auth",
    consent: "consent",
    wednesday: 3
});

var getEndpoint = function () {
    return window.config.url;
};

IDPConnectService.fetchLoginPage = function (isLoginPage, deviceAuth, sp_name) {
    const baseUrl = getEndpoint();
 
    if (deviceAuth) {
        return `${baseUrl}/login/idps?dark_mode=false&page=${isLoginPage}&theme=${window.config.theme}&user_code=${deviceAuth.user_code}&verification_uri_complete=${deviceAuth.verification_uri_complete}&sp_name=${sp_name}&platform=${detectDevice()}`;
    } else {
        return `${baseUrl}/login/idps?dark_mode=false&page=${isLoginPage}&theme=${window.config.theme}&platform=${detectDevice()}`;
    }
};


IDPConnectService.ListIdps = function() {
    return new Promise((resolve, reject) => {
        IdpApi
            .getIdps()
            .then(result => {
                    resolve(result)
                }
            ).catch(e => {
                reject(e)
            })
    })
};

/**

!!! This sets state in the AuthService, login session id and secret

@param idp The idp object form the IdpApi
@param after ... what to do on return?

@return Promise with url for reidriect
*/
IDPConnectService.LoginToIdp = function(idp, after, last_view) {
    return new Promise((resolve, reject) => {
        IdpApi.requestIdpAuth(idp).then(
            result => {
                BrowserStorage.set("idp_after_type", after);
                BrowserStorage.set("idp_id", idp);
                BrowserStorage.set(
                    "idp_request_id",
                    result.pending_auth_request_id
                );
                BrowserStorage.set("idp_request_secret", result.session_key);
                BrowserStorage.set("idp_last_view_name", last_view);

                resolve(result.auth_request);
            },
            error => reject(error)
        );
    });
};

IDPConnectService.LoginToIdpCallback = function() {
    let idp_after_type = BrowserStorage.get("idp_after_type");
    let idp_id = BrowserStorage.get("idp_id");
    let req_id = BrowserStorage.get("idp_request_id");
    let req_secret = BrowserStorage.get("idp_request_secret");
    let token = SessionStorage.get("cr_token");
    let last_view = BrowserStorage.get("idp_last_view_name");

    if (idp_id == null || req_id == null || req_secret == null) {
        return Promise.reject(
            "pending idp login request missing or incomplete"
        );
    }
    
    return new Promise((resolve, reject) => {
        IdpApi.requestIdpAuthState(idp_id, req_id, req_secret).then(
            async (result) => {
                let currentToken = token;

                if (result.wallet_session_token) {
                    BrowserStorage.set("session", result.wallet_session_token);
                }
                
                if (last_view === "CONSENT_QR") {
                    try {
                        await ConsentRequestService.receiveRequestWithToken(currentToken);
                    } catch (e) {
                        reject(e)
                    }
                }

                let redirectTo = "profile";
                
                const tokenHistory = ConsentRequestService.tokenHistory();
                    
                if ((tokenHistory && tokenHistory !== "null" && tokenHistory !== "undefined" && Object.keys(tokenHistory).length) || (idp_after_type === IDPAfter.consent)) {
                    redirectTo = `consent/${currentToken}`;
                } 

                // Case for 403 when deleting an account
                if (BrowserStorage.get("delete_reason")) {
                    redirectTo = "settings";
                }

                result.internal_redirect = window.GLOBAL_PATH+redirectTo;
                result.last_view = last_view;

                resolve(result);

                // clean up tranasction state
                BrowserStorage.remove("idp_id")
                BrowserStorage.remove("idp_request_id");
                BrowserStorage.remove("idp_request_secret");
                BrowserStorage.remove("idp_after_type");
                BrowserStorage.remove("idp_last_view_name");
            },
            error => {
                error.last_view = last_view;
                reject(error)
            }
        );
    });
};

// IDPConnectService.requestConnection = function(dsid) {
//     return new Promise((resolve, reject) => {});
// };

export default IDPConnectService;
