import React from "react";

// Lib
import i18n from "../../../i18n";
import Modal from "react-modal";

// Components
import Spinner from "../../Spinner";

const DeclineModal = ({ isOpen, handleToggleDeclineModal, handleDeclineAction, request, isDeclineRequest }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={!isDeclineRequest ? handleToggleDeclineModal : () => { return undefined; }}
      ariaHideApp={true}
      overlayClassName="modal-overlay"
      contentLabel={i18n.t("consent__decline_title")}
    >
      <div className="modal-wrapper decline-wrapper">
        <h2 className="header">
          {
            i18n.t("consent__decline_title")
          }
        </h2>

        <div className="modal-body" dangerouslySetInnerHTML={{ __html: i18n.t('consent__decline_desc', { client: request.client.name }) }} />
        
        <div className="footer">
          <button disabled={isDeclineRequest} className="noThanksButton textPrimaryColor borderPrimaryColor" onClick={
            handleToggleDeclineModal} >
            {
              i18n.t("consent__decline_btn2")
            }
          </button>
          
          {
            isDeclineRequest
            ?
            <button className="disconnectButton backgroundPrimaryColor button--spinner">
              <Spinner />
            </button>
            :
            <button className="disconnectButton backgroundPrimaryColor" onClick={handleDeclineAction}> 
              {
                i18n.t("consent__decline_btn1")
              }
            </button>
          }

        </div>
      </div>
    </Modal>
  );
}

export default DeclineModal;