import React, { Component } from "react";

// Lib
import i18n from "../i18n";
import Modal from "react-modal";
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

// Components
import Spinner from "./Spinner";

class EditNicknameModal extends Component {
  state = {
    nickname: this.props.nickname || ""
  }

  handleOnChange = (e) => {

    const { value } = e.target; 

    this.setState({ nickname: value })
  }

  render() {
    const {
      isOpen,
      handleToggleModal,
      handleEditNicknameAction,
      isSendingReq,
      isInternetDisconnected,
      handleToggleNetworkModal,
      title,
      description,
      description2,
      hasTitleIcon,
      inModalMessage,
      errorMessage,
    } = this.props;

    const { nickname } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={handleToggleModal}
        ariaHideApp={false}
        overlayClassName="modal-overlay"
        contentLabel={title}
      >
        <div className="modal-wrapper find-connection-modal connectiion-detail-edit-nickname-modal">
          <div className="header">
            {
              hasTitleIcon
              &&
              <div className="check-icon-wrapper borderPrimaryColor">
                <CheckOutlinedIcon className="check-icon textPrimaryColor" />
              </div>
            }
            <h2>{ title }</h2>
          </div>

          <div 
            className="modal-body"
            dangerouslySetInnerHTML = {{ __html: description }}
          />

          {
            description2
            &&
            <div className="modal-body-2">
              { description2 }
            </div>
          }
          
          <div className="input-wrapper">
            <input 
              className="search-input edit-input"
              name="nickname"
              type="text" 
              onChange={this.handleOnChange} 
              placeholder={i18n.t('edit_nickname_modal__input_placeholder')} 
              value={nickname}
              maxLength="30"
            />

            <span className={inModalMessage ? "error error-editModal" : "error"}>
              {errorMessage}
            </span>
          </div>

          <div className="footer">
            {
              isInternetDisconnected
              ?
              <button 
                className="disconnectButton backgroundPrimaryColor"
                onClick={handleToggleNetworkModal} 
              >
                {
                  i18n.t("edit_nickname_modal__submit_button")
                }
              </button>
              :
              isSendingReq
              ?
              <button className="disconnectButton backgroundPrimaryColor button--spinner">
                <Spinner />
              </button>
              :
              <button 
                className="disconnectButton backgroundPrimaryColor"
                onClick={() => handleEditNicknameAction(nickname)}
                disabled={!nickname || isSendingReq} 
              >
                {
                  i18n.t("edit_nickname_modal__submit_button")
                }
              </button>
            }
          </div>
        </div>
      </Modal>  
    );
  }
}

export default EditNicknameModal;