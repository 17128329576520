import React from "react";

// Lib
import Modal from "react-modal";

const IconSuccessModal = ({ isOpen, titleText, bodyText, buttonText, handleButtonAction, isInternetDisconnected, handleToggleNetworkModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={true}
      overlayClassName="modal-overlay"
      contentLabel={titleText}
    >
      <div className="modal-wrapper find-connection-modal icon-success-modal">
        <div className="header">
          <span className="icon-iconCheck"></span>
          <h2>{titleText}</h2>
        </div>

        <div className="modal-body">
          <p>{bodyText}</p>
        </div>

        <div className="footer">
          {
            isInternetDisconnected
            ?
            <button onClick={handleToggleNetworkModal} className="okBtn  backgroundPrimaryColor">
              { buttonText }
            </button>
            :
            <button onClick={handleButtonAction} className="okBtn  backgroundPrimaryColor">
              { buttonText }
            </button>
          }
        </div>
      </div>
    </Modal>
  );
}

export default IconSuccessModal;