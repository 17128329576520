import React, { Component } from "react";

// Libs
import { Redirect } from "react-router-dom";
import qs from "query-string";

// Services
import ConsentRequestService from "./Services/ConsentRequestService";

// Components
import Spinner from "./Spinner";

class AsRequestRouter extends Component {
  constructor(props) {
    super(props);

    // TODO: if not authenticated redirect to the login & qr page
    const queryParts = qs.parse(this.props.location.search);
  
    // TODO: if not query redirect _somewhere_

    this.state = {
      requestId: queryParts.token,
      client: queryParts.client,
      redirectTo: null
    };
  }

  componentDidMount = () => {
    // TODO: pull the token off of the query param
    return ConsentRequestService.receiveRequestWithToken(this.state.requestId, this.state.client).then(
      result => {
        this.setState({ redirectTo: result });
      }
    );
  }

  render() {
    const { redirectTo } = this.state;

    if (redirectTo === null) {
      return (
        <div className="redirect-router-spinner-wrapper">
          <Spinner />
        </div>
      );
    }

    return <Redirect to={{ pathname: redirectTo }} />;
  }
}

export default AsRequestRouter;